// Reference Image: https://share.anysnap.app/fDbpmBOjr7BH
import { useEffect, useRef } from "react";
import { viewport } from "../../../styles/cssinjs/helpers/variables.js";

export default function TpWidgetSlider() {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null);
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <>
      <div className="container trustpilot-widget-macro lg:mb-5 md:mb-5">
        <div
          ref={ref} // We need a reference to this element to load the TrustBox in the effect.
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="5942b0540000ff0005a4a45a"
          data-style-height="auto"
          data-style-width="100%"
          data-theme="light"
          data-stars="3,4,5"
          data-review-languages="en"
          data-font-family="Lato"
          data-text-color="#303A47"
        >
          <a
            href="https://trustpilot.com/review/setmore.com?utm_medium=trustbox&utm_source=MicroStar"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
        <style jsx>{`
          .trustpilot-widget {
            min-height: 150px;
          }
          @media (max-width: ${viewport.mobile.lg}) {
            .trustpilot-widget {
              min-height: 300px;
            }
          }
        `}</style>
      </div>
    </>
  );
}
